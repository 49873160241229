/* eslint-disable camelcase */
import { DeviceTunnelRequestStatus } from '@/models/Enums';

export interface IRestDeviceVariable {
  device_id: number;
  device_name: string;
  device_short_label?: string;
  variables: string[];
}

export interface IDeviceVariables {
  deviceId: number;
  deviceName: string;
  deviceShortLabel?: string;
  variables: string[];
}

export interface IRestApiDevice {
  id: number;
  uuid: string;
  name: string;
  short_label: string;
  direct_link: string;
  tunnel_subdomain: string;
  tunnel_key: string;
  tunnel_request_status: DeviceTunnelRequestStatus;
  plexus_lab_key: string;
  is_tunnel_alive: boolean;
  system_status: string;
  // created_at: string;
}

export class Device {
  id: number;
  uuid: string;
  name: string;
  shortLabel: string;
  directLink: string;
  tunnelSubdomain: string;
  tunnelKey: string;
  tunnelRequestStatus: DeviceTunnelRequestStatus;
  plexusLabKey: string;
  isTunnelAlive: boolean;
  systemStatus: string;
  // createdAt: Date;

  get tunnelStatusString(): string {
    switch (this.tunnelRequestStatus) {
      case DeviceTunnelRequestStatus.Off:
        return "Off"
      default:
        return "On"
    }
  }

  get tunnelLink(): string | null {
    if (this.tunnelRequestStatus === DeviceTunnelRequestStatus.On && this.tunnelKey != null) {
      return `https://${this.tunnelKey}.plexus-automation.com/plexus/panel.php`;
    }
    return null;
  }

  constructor(data: IRestApiDevice) {
    this.id = data.id
    this.uuid = data.uuid
    this.name = data.name
    this.shortLabel = data.short_label
    this.directLink = data.direct_link
    this.tunnelSubdomain = data.tunnel_subdomain
    this.tunnelKey = data.tunnel_key
    this.tunnelRequestStatus = data.tunnel_request_status
    this.plexusLabKey = data.plexus_lab_key
    this.isTunnelAlive = data.is_tunnel_alive
    this.systemStatus = data.system_status
  }

  update(device: Device): void {
    this.id = device.id
    this.uuid = device.uuid
    this.name = device.name
    this.shortLabel = device.shortLabel
    this.directLink = device.directLink
    this.tunnelSubdomain = device.tunnelSubdomain
    this.tunnelKey = device.tunnelKey
    this.tunnelRequestStatus = device.tunnelRequestStatus
    this.plexusLabKey = device.plexusLabKey
    this.isTunnelAlive = device.isTunnelAlive
  }

  clone(): Device {
    return new Device({
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      short_label: this.shortLabel,
      direct_link: this.directLink,
      tunnel_subdomain: this.tunnelSubdomain,
      tunnel_key: this.tunnelKey,
      tunnel_request_status: this.tunnelRequestStatus,
      plexus_lab_key: this.plexusLabKey,
      is_tunnel_alive: this.isTunnelAlive,
      system_status: this.systemStatus,
    })
  }
}
